import React, { useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import SidebarV2NavItem from './SidebarV2NavItem'
import { NavigationContext } from '../../NavigationContext'
import SidebarV2HelpLink from './SidebarV2HelpLink'

const useStyles = makeStyles(() => ({
    sidebar: {
        width: '78px',
        minWidth: '78px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        backgroundColor: '#002119',
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
            width: '4px',
            backgroundColor: 'transparent'
        }
    },
    navItemList: {
        display: 'flex',
        flexDirection: 'column'
    }
}))

/**
 * Displays the top-level nav items in a green, vertical sidebar
 */
const SidebarV2 = () => {
    const classes = useStyles()

    const { getNavItems } = useContext(NavigationContext)

    const topLevelNavItems = getNavItems()

    return (
        <div className={classes.sidebar}>
            <div className={classes.navItemList}>
                {topLevelNavItems.filter(n => !n.pinned).map(navItem => (
                    <SidebarV2NavItem
                        key={navItem.id}
                        navItem={navItem}
                    />
                ))}
            </div>

            <div className={classes.navItemList}>
                {topLevelNavItems.filter(n => n.pinned).map(navItem => (
                    <SidebarV2NavItem
                        key={navItem.id}
                        navItem={navItem}
                    />
                ))}

                <SidebarV2HelpLink />
            </div>
        </div>
    )
}

export default SidebarV2
